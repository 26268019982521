/*
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

@font-face {
    font-family: 'Kufi';
    font-weight: normal;
    font-display: swap;
    font-style: normal;
    src: url("KufiRegular.woff2") format('woff2'),
    url('KufiRegular.woff') format('woff'),
    url('KufiRegular.ttf') format('truetype'),
    url("KufiRegular.svg") format("svg");
}
