/*!
 * Website: https://www.4myth.com
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Copyright © 2018-2024  All rights reserved.
 * MyTh Ahmed
 */

@import "../dist/fonts/Kufi/stylesheet.css";
@import "../dist/fonts/Sans/stylesheet.css";

$app-btn-margins: 10px !default;

html {
  overflow: auto !important;
}

.v-application, body,
.v-application [class*='text-'] {
  font-family: 'Kufi', 'Sans', sans-serif !important;
}

::-webkit-scrollbar {
  width: 10px;

}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0;
  //border: 1px solid #4a4a4a;
}

::-webkit-scrollbar-thumb {
  //background: #4a4a4a;
  background: var(--v-secondary-base);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  //background: #4a4a4a;
  background: var(--v-secondary-base);
}

.w-100 {
  width: 100% !important;
}

.v-application--is-rtl .app-row > .app-btn {
  margin-left: $app-btn-margins;
}

.v-application--is-ltr .app-row > .app-btn {
  margin-right: $app-btn-margins;
}

.app-fab-btn {
  position: fixed !important;
  z-index: 15000;
}

.app-fab-btn.left {
  right: 10px;
}

.app-fab-btn.right {
  left: 10px;
}

.app-fab-btn.top {
  top: 10px;
}

.app-fab-btn.bottom {
  bottom: 10px;
}

@media print {
  .app-fab-btn {
    display: none !important;
  }
}

.v-card__title {
  word-break: break-word !important;
}

.pointer {
  cursor: pointer !important;
}

.direction-ltr {
  direction: ltr;
}

.direction-rtl {
  direction: rtl;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
